import Vue from "vue";
import App from "./App.vue";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
// import "vue-material/dist/theme/default-dark.css";
import "@/assets/css/theme.scss";
import "@/assets/css/common.css";

Vue.use(VueMaterial);

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

// import animated from "animate.css";
// Vue.use(animated);
import "wowjs/css/libs/animate.css";

// import MenuIcon from "vue-material-design-icons/Menu.vue";
// Vue.component("menu-icon", MenuIcon);
// import MaterialIcon from "vue-material-design-icons";
// import MaterialIcon from "material-icons-vue";
// Vue.use(MaterialIcon);

import "material-design-icons-iconfont/dist/material-design-icons.css";

import VueRouter from "vue-router";
import routers from "./router/index.js";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: routers,
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
