<template>
  <div id="app">
    <router-view></router-view>
    <foot></foot>
  </div>
</template>

<script>
import foot from '@/components/foot/index.vue'
export default {
  name: 'App',
  components: {
    foot,
  }
}
</script>

<style lang="scss">
body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
  height: 100%;
  min-height: 100%;
}
</style>
