<template>
  <div class="example">
    <div>
      <div class="backToTop flex">
        <md-icon>
          phone_in_talk
        </md-icon>
        <span>咨询热线</span>
        <div class="phoneBox flex">18888888888</div>
      </div>
      <div class="backToTop flex" @click="backTop" v-if="backShowFlag">
        <md-icon>
          keyboard_capslock
        </md-icon>
        <span>返回顶部</span>
      </div>
    </div>
    <!-- <md-speed-dial :class="bottomPosition">
      <md-speed-dial-target>
        <md-icon>
          tips_and_updates</md-icon>
      </md-speed-dial-target>

      <md-speed-dial-content>
        <md-button class="md-icon-button" title="ddsaf">
          <md-icon>
            perm_phone_msg
          </md-icon>
        </md-button>
        <md-button class="md-icon-button" @click="backTop" v-if="backShowFlag">
          <md-icon>
            arrow_circle_up</md-icon>
        </md-button>


      </md-speed-dial-content>
    </md-speed-dial> -->
  </div>
</template>

<script>
export default {
  name: 'PositionDirection',
  props: ['backShowFlag'],
  data() {
    return {
      bottomPosition: 'md-bottom-right'
    }
  },
  methods: {
    backTop() {
      this.$emit('backToTop')
    },
  }
}
</script>

<style lang="scss" scoped>
.example {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  min-height: 300px;

  .backToTop {
    flex-direction: column;
    margin-bottom: 4px;
    font-size: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: all 0.2s;
    background: #dfdede;
    color: #192850;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .phoneBox {
      position: absolute;
      right: -220px;
      top: 0;
      height: 60px;
      width: 220px;
      background: #dfdede;
      border-top-left-radius: 38px;
      border-bottom-left-radius: 38px;
      transition: all 0.1s;
      z-index: 1000;
      font-size: 20px !important;
    }

    .md-icon.md-theme-default.md-icon-font {
      font-size: 26px !important;
      color: #192850;
      margin: 0;
      margin-bottom: 4px;
    }
  }

  .backToTop:hover {
    background: linear-gradient(to right, #192850, #263150) !important;
    color: #dfdede;

    .phoneBox {
      right: 48px;
      background: linear-gradient(to right, #061a50, #192850) !important;
    }

    .md-icon.md-theme-default.md-icon-font {
      color: #dfdede;
    }
  }
}

.demo-option {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .select {
    margin: 0 6px;
    display: inline-flex;
    width: auto;
  }
}
</style>