<template>
  <div class="foot flex">
    <div class="foot-top flex">
      <div class="top-inner flex">
        <div class="flex-start des">
          <div class="left flex-start">
            <p class="title">辽宁省元一网络科技有限公司</p>
            <div class="desLeftList flex-start">
              <p>
                <md-icon style="color: #fff;margin-right: 10px;">
                  business</md-icon>
              </p>
              <p>台湾省台北市士林区至善路二段221号</p>
            </div>
          </div>
          <div class="right flex-start">
            <p class="title">联系方式</p>
            <div class="desLeftList flex-start">
              <p>
                <md-icon style="color: #fff;margin-right: 10px;">
                  mail</md-icon>
              </p>
              <p>wahaha@gmail.com</p>
            </div>
            <div class="desLeftList flex-start">
              <p>
                <md-icon style="color: #fff;margin-right: 10px;">
                  phone</md-icon>
              </p>
              <p>18888888888</p>
            </div>
          </div>
        </div>
        <div class="myLinks flex">
          <p class="title">友情链接</p>
          <div class="lint-list">
            <a href="javascript;">哇哈哈</a>
            <a href="javascript;">君乐宝</a>
            <a href="javascript;">怡宝</a>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-bot flex">
      <div class="bot-inner flex flex_between">
        <div>
          Copyright ©2023辽宁省元一网络科技有限公司 版权所有 增值电信业务经营许可证： 合字B2-20200094 合字B2-20200096 鲁ICP备2021010660号
          鲁公网安备37021202001456号
        </div>
        <div>
          用户服务协议 ｜ 隐私政策
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.foot {
  width: 100%;
  background: #333;
  flex-direction: column;

  .foot-top {
    background: #333;
    color: #bbb;
    width: 100%;

    .top-inner {
      width: 1200px;
      flex-direction: column;
      padding: 40px 0;

      .des {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        justify-content: space-between;
        color: #fff;

        .left {
          flex: 1;
          flex-direction: column;
        }

        .right {
          width: 400px;
          flex-direction: column;
        }

        .title {
          font-size: 20px;
        }

        .desLeftList {
          font-size: 16px;
        }
      }

      .myLinks {
        width: 100%;
        margin-top: 20px;
        align-items: flex-start;
        flex-direction: column;

        .title {
          font-size: 14px;
          font-weight: 600;
        }

        .lint-list {
          a {
            font-size: 12px;
            color: #bbb;
            padding-right: 40px;
            position: relative;
          }

          a:before {
            content: '';
            height: 100%;
            width: 1px;
            background: rgba(187, 187, 187, 0.5);
            position: absolute;
            right: 20px;
            top: 0;
          }

          a:last-of-type:before {
            display: none;
          }
        }
      }
    }
  }

  .foot-bot {
    background: #000;
    color: #bbb;
    width: 100%;
    font-size: 12px;
    height: 36px;

    .bot-inner {
      width: 1200px;
    }
  }
}
</style>