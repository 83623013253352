<template>
  <div class="page-container aaa" ref="mianscroll">
    <div :class="['top', 'flex', scrollTop >= 1 && scrollTop < 590 ? 'top1' : scrollTop >= 590 ? 'top2' : '']">
      <div class="top-inner flex">
        <div class="title wow slideInDown">元一网络科技</div>
        <div>
          <md-button class="md-icon-button md-dense md-primary">
            <md-icon>person</md-icon>
          </md-button>
        </div>
      </div>
    </div>
    <swiper class="swiper" :options="swiperOption" style="height: 650px" ref="mySwiper">
      <swiper-slide>
        <div :class="['backgroundImg', 'backgroundImg1', isActive == 1 ? 'active' : '']"></div>
      </swiper-slide>
      <swiper-slide>
        <div :class="['backgroundImg', 'backgroundImg2', isActive == 2 ? 'active' : '']"></div>
      </swiper-slide>
      <swiper-slide>
        <div :class="['backgroundImg', 'backgroundImg3', isActive == 3 ? 'active' : '']"></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="nap-box">
      <a-affix :offset-top="80">
        <div :class="['nap-container', scrollTop >= 590 ? 'nap-container1' : '']">
          <div class="nap-inner">
            <div class="item flex" @click="goDingyue(1)">
              <img class="img" src="@/assets/image/hosp.png" alt="" />
              <div class="item-des">
                <h2>互联网医院</h2>
                <!-- <p>为医院带来一种全新的医疗服务体验，将高效、便捷与专业融为一体</p> -->
              </div>
            </div>
            <div class="item flex" @click="goDingyue(2)">
              <img class="img" src="@/assets/image/store.png" alt="" />
              <div class="item-des">
                <h2>医路伴学</h2>
                <!-- <p>为医院带来一种全新的医疗服务体验，将高效、便捷与专业融为一体</p> -->
              </div>
            </div>
            <div class="item flex" @click="goDingyue(3)">
              <img class="img" src="@/assets/image/submitAccount.png" alt="" />
              <div class="item-des">
                <h2>医保报销助手</h2>
                <!-- <p>为医院带来一种全新的医疗服务体验，将高效、便捷与专业融为一体</p> -->
              </div>
            </div>
            <div class="item flex" @click="goDingyue(4)">
              <img class="img" src="@/assets/image/prescriptionRoam.png" alt="" />
              <div class="item-des">
                <h2>处方流转</h2>
                <!-- <p>为医院带来一种全新的医疗服务体验，将高效、便捷与专业融为一体</p> -->
              </div>
            </div>
          </div>
        </div>
      </a-affix>
    </div>
    <div class="my-des flex-start">
      <!-- <div class=""></div> -->
      <section class="wow slideInLeft my-des-left">公司简介</section>
      <div class="my-des-right flex-start">
        <section class="wow lightSpeedIn my-des-p">
          辽宁省元一网络科技有限公司成立于2023年，致力于将人工智能技术应用于医疗医药科技领域，旨在提高医疗效率和患者用药效率，为提高患者的用药效果提供技术与服务支持，公司的服务已经获得了众多客户的认可和好评。已经与国内多家知名医院与零售药店合作。
        </section>
        <section class="wow lightSpeedIn my-des-p">
          技术团队拥有人工智能算法、深度学习模型和自然语言处理等方面的核心技术能力。这些技术能够根据庞大的医疗数据进行分析和学习，以提供更加精准用药建议。我们的技术团队由多名具有丰富经验的工程师组成，团队人工智能和医疗领域有深入的理解和独特的见解。
        </section>
      </div>
    </div>
    <div class="company-culture flex">
      <div class="company-culture-inner flex-start">
        <div class="company-culture-inner-left wow slideInLeft">企业文化</div>
        <div class="company-culture-inner-right flex-start">
          <p class="wow lightSpeedIn">我们以客户为中心，全力以赴创造价值</p>
          <p class="wow lightSpeedIn">我们是开放团队，正直互信合作共赢</p>
          <p class="wow lightSpeedIn">我们是创业者，主动担当锐意变革</p>
          <p class="wow lightSpeedIn">我们是创新者，坚持不懈推陈创新</p>
          <p class="wow lightSpeedIn">我们说到做到、尽心尽力、成就客户</p>
        </div>
      </div>
    </div>
    <div class="company-culture company-culture2 flex">
      <div class="company-culture-inner flex-start">
        <div class="company-culture-inner-left wow slideInLeft">企业使命</div>
        <div class="company-culture-inner-right flex-start">
          <p class="wow lightSpeedIn">作为医疗信息化行业的领导者引领健康领域产业链变革，</p>
          <p class="wow lightSpeedIn">为提升中国的医疗水平赋能</p>
        </div>
      </div>
    </div>
    <div style="" class="content_box content_box1 flex">
      <div class="inner">
        <h2 class="title">互联网医院</h2>
        <div class="viewMore flex">
          <p class="viewMoreText">查看详情</p>
          <md-icon>arrow_forward_ios</md-icon>
        </div>
        <div>
          <p>
            我们为医院带来一种全新的医疗服务体验，将高效、便捷与专业融为一体。我们用科技连接患者与医生，构建起一个全方位、个性化的医院闭环互联网医院平台。我们利用先进的信息技术，为互联网医院保驾护航。
          </p>
          <p>
            我们的服务已经在众多医院得到了广泛的好评。从诊前的智能导诊，预问诊，到诊中的问诊、处方流转，到诊后的随访，慢性病管理，再到日常的健康咨询，我们的团队协助医院以高度的专业性和贴心关怀，赢得了患者的信任。每一个成功的案例都证明了我们服务的实用性和可靠性。
          </p>
        </div>
      </div>
    </div>
    <div class="content_box content_box1 content_box2 flex">
      <div class="inner">
        <h2 class="title">医路伴学</h2>
        <div class="viewMore flex">
          <p class="viewMoreText">查看详情</p>
          <md-icon>arrow_forward_ios</md-icon>
        </div>
        <div>
          <p>学生之间模拟客观结构化临床考试的联系学习应用，包含互相点评、互相学习，名师点评，模拟考试等模块。</p>
          <p></p>
        </div>
      </div>
    </div>
    <div class="content_box content_box3 flex">
      <div class="inner">
        <h2 class="title">医保报销助手</h2>
      </div>
    </div>
    <div class="content_box content_box4 flex">
      <div class="inner">
        <h2 class="title">处方流转</h2>
      </div>
    </div>
    <speedDial @backToTop="backUp" :backShowFlag="scrollTop >= 10"></speedDial>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import speedDial from '@/components/speedDial/index.vue'
import 'swiper/css/swiper.css'
import { WOW } from 'wowjs'
export default {
  name: 'Reveal',
  data() {
    let that = this
    return {
      menuVisible: false,
      isActive: 0,
      scrollTop: 0,
      activeIndex: 0,
      theme: 'teal',
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        autoplay: {
          delay: 122000,
        },
        loop: false, // 循环模式选项
        watchActiveIndex: true,
        on: {
          slideChangeTransitionEnd: function () {
            that.isActive = this.activeIndex + 1
          },
        },
      },
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    speedDial,
  },
  computed: {},
  created() {},
  mounted() {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()

    setTimeout(() => {
      this.isActive = 1
    }, 1)
    // 向页面添加股东事件
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    // 返回顶部
    backUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    goDingyue(val) {
      // console.log(this.$refs.content_box_ref.getBoundingClientRect());
      this.$el.querySelector(`.content_box${val}`).scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    },
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  height: auto;
  background: #fafafa;

  .md-app {
    height: 100%;
    border: 1px solid rgba(#000, 0.12);
  }

  // Demo purposes only
  .md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
  }
}
</style>
<style lang="scss" scoped>
.phone-viewport {
  width: 322px;
  height: 200px;
  display: inline-flex;
  align-items: flex-end;
  overflow: hidden;
  border: 1px solid rgba(#000, 0.26);
  background: rgba(#000, 0.06);
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.phone-viewport {
  width: 322px;
  height: 200px;
  display: inline-flex;
  align-items: flex-end;
  overflow: hidden;
  border: 1px solid rgba(#000, 0.26);
  background: rgba(#000, 0.06);
}
</style>
<style lang="scss">
// 公司简介 start
.my-des {
  width: 1200px;
  padding: 90px 0 0;
  margin: 0 auto;

  .my-des-left {
    font-size: 36px;
    width: 400px;
    color: #f05a23;
  }

  .my-des-right {
    flex: 1;
    flex-direction: column;

    .my-des-p {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-indent: 40px;
      font-size: 18px;
      line-height: 30px;
      text-align: left;
    }
  }
}

// 公司简介 end

// 企业文化 start
.company-culture {
  max-width: 1900px;
  margin: 90px auto 0;
  height: 360px;
  background: url('https://hccm-1256080859.cos.ap-beijing.myqcloud.com/image/20230919/1695116300796.jpg') center no-repeat;
  background-size: cover;

  .company-culture-inner {
    width: 1200px;

    .company-culture-inner-left {
      font-size: 36px;
      width: 400px;
      color: #fff;
    }

    .company-culture-inner-right {
      flex: 1;
      flex-direction: column;
      color: #fff;
      font-size: 18px;
    }
  }
}

.company-culture2 {
  max-width: 1900px;
  margin: 90px auto 0;
  height: 360px;
  background: url('https://hccm-1256080859.cos.ap-beijing.myqcloud.com/image/20230919/1695116303283.jpg') center no-repeat;
  background-size: cover;
}

// 企业文化 end

.top {
  height: 80px;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  position: fixed;
  top: 0;
  z-index: 10000000;
  color: #fff;

  .top-inner {
    width: 1200px;
    justify-content: space-between;

    .title {
      font-size: 28px;
      font-weight: 600;
    }
  }
}

.top1 {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.95);
  color: #192850;
}

.top2 {
  display: none;
}

.swiper-container {
  height: 650px !important;
}

.swiper-pagination-bullet-active-main {
  background: #192850;
}

.swiper-slide {
  background: #fff;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 130px;
}

.nap-box {
  position: relative;
  height: 1px;
  width: 100%;

  .nap-container {
    width: 100%;
    position: absolute;
    top: -80px;
    z-index: 1000;
    height: 80px;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5);

    .nap-inner {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .item {
        flex: 1;
        padding: 0 20px;
        cursor: pointer;

        .img {
          height: 40px;
          width: 40px;
          margin-right: 10px;
        }

        .item-des {
          h2 {
            font-weight: 600;
            margin: 0;
          }

          p {
            margin: 0;
            color: #525151;
          }
        }
      }
    }
  }

  .nap-container1 {
    background: #fff;
    box-shadow: 0px 2px 5px rgba(25, 40, 80, 0.2);
  }
}

.backgroundImg {
  height: 650px;
  width: 100%;
  transform: scale(1);
  transform-origin: center center 0;
  transition: all 0.6s ease 1s;
}

.backgroundImg1 {
  background: url('https://hccm-1256080859.cos.ap-beijing.myqcloud.com/image/20230918/1695006933538.jpg') center no-repeat;
  background-size: cover;
}

.backgroundImg2 {
  background: url('https://hccm-1256080859.cos.ap-beijing.myqcloud.com/image/20230919/1695089610413.jpg') center no-repeat;
  background-size: cover;
}

.backgroundImg3 {
  background: url('https://hccm-1256080859.cos.ap-beijing.myqcloud.com/image/20230919/1695089638242.jpg') center no-repeat;
  background-size: cover;
}

.active {
  transform: scale(1.2);
  transition: all 2s cubic-bezier(0.55, 0.06, 0.68, 0.19) 1s;
}

.content_box {
  width: 100%;
  height: 1000px;
  padding-top: 100px;
  flex-direction: column;
  justify-content: flex-start;

  .inner {
    width: 1200px;

    .title {
      font-weight: 600;
      font-size: 32px;
    }
  }
}

.content_box1 {
  margin: 0 auto;
  max-width: 1900px;
  background: url('https://hd-oss.cosmoplat.com/hdCosmo25%3Acosmoplat/202308/4196e5c8-6dbd-4c3a-914d-44c4674ec680.jpg') center no-repeat;
  background-position: center 80px;
  background-size: cover;
  height: 600px;
}

.viewMore {
  cursor: pointer;
  font-size: 14px;
  width: 160px;
  position: relative;
  margin: 0 auto;

  .viewMoreText {
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-right: 6px;
  }

  .md-icon.md-theme-default.md-icon-font {
    position: absolute;
    right: 26px;
    font-size: 20px !important;
    margin-left: 0;
    top: -1px;
    transition: all 0.3s;
  }
}

.viewMore:hover {
  color: #0831a1;

  .md-icon.md-theme-default.md-icon-font {
    color: #0831a1 !important;
    right: 20px;
    transition: all 0.3s;
  }
}
</style>
